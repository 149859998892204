import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Post from '../templates/Post';
import Meta from '../components/Meta';
import Layout from '../components/Layout';
import Interstitial from '../components/Interstitial';

const WhatsAppRevoke = ({ data, location }) => (
  <Layout location={location}>
    <Meta
      title="Save our Future - Revoke Article 50"
      description="We&rsquo;ll generate a message for you to send your friends. Let’s make Revoke go viral!"
      shareImage="revoke"
      location={location}
      site={get(data, 'site.meta')}
      whatsAppMessage={`Save our Future!

Say NO to a catastrophic Brexit and YES to REVOKING Article 50!

💥 *Share* this message with five top friends and groups on WhatsApp - click here:
➡ https://saveourfuture.uk/revoke ⬅

💥 *Sign* the petition here
➡ https://saveourfuture.uk/petition ⬅

Let’s get the petition going viral.`}
    />
    <Interstitial />
  </Layout>
);

WhatsAppRevoke.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default WhatsAppRevoke;

export const query = graphql`
  query RevokeQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
        adsense
      }
    }
  }
`;
